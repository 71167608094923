export const Clients_Action_Types = {
    FETCH_CLIENTS_START: "clients/FETCH_CLIENTS_START",
    FETCH_CLIENTS_SUCCESS: "clients/FETCH_CLIENTS_SUCCESS",
    FETCH_CLIENTS_FAILED: "clients/FETCH_CLIENTS_FAILED",
    SET_PAGE_SIZE: "clients/SET_PAGE_SIZE",
    SET_IS_UPDATED: "clients/SET_IS_UPDATED",
    SET_FILTER_SEARCH_STRING: "clients/SET_FILTER_SEARCH_STRING",
    SET_FILTER_FROM_DATE: "clients/SET_FILTER_FROM_DATE",
    SET_FILTER_TO_DATE: "clients/SET_FILTER_TO_DATE",
    SET_FILTER_GENDER: "clients/SET_FILTER_GENDER",
    SET_FILTER: "clients/SET_FILTER",
    RESET_STATE: "clients/RESET_STATE"
}