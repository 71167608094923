import { createStore, compose, applyMiddleware} from "redux";
import {persistStore} from "redux-persist"
import thunk from "redux-thunk"
import rootReducer from "./root-reducer";


const composeEnhancer = (process.env.NODE_ENV !== 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export const store = createStore(rootReducer, undefined, composeEnhancer(applyMiddleware(thunk)))

export const persistor = persistStore(store) 