import { Staff_Action_Types } from "./staff.types";
import axios from "axios"

export const fetchStaffStart = () => ({
        type: Staff_Action_Types.FETCH_STAFF_START
    })

export const fetchStaffSuccess = (staffArray) => ({
        type: Staff_Action_Types.FETCH_STAFF_SUCCESS,
        payLoad: staffArray
    })

export const fetchStaffOptionList = (staffArray) => ({
        type: Staff_Action_Types.FETCH_STAFF_OPTION_LIST,
        payLoad: staffArray
    })

export const fetchStaffFailed = (error) => ({
        type: Staff_Action_Types.FETCH_STAFF_FAILED,
        payLoad: error
    })

export const setPageSize = (pageSize) => ({
        type: Staff_Action_Types.SET_PAGE_SIZE,
        payLoad: pageSize
    })

export const setIsUpdated = () => ({
        type: Staff_Action_Types.SET_IS_UPDATED
    })

export const setFilterSearchString = (searchString) => ({
    type: Staff_Action_Types.SET_FILTER_SEARCH_STRING,
    payLoad: searchString
})

export const FetchStaffAsync = ( pageIndex, pageSize, signal) => async(dispatch, getState) => {
    const accessToken = getState().user.accessToken
    const apiBaseName = getState().reducer.apiBaseName
    const {searchString} = getState().staff.filter

    dispatch(fetchStaffStart())
    await axios({
        method: "get",
        signal: signal,
        url: `${apiBaseName}/employees/list?page=${
            pageIndex + 1
          }&limit=${pageSize}&searchString=${searchString}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(function (response) {
            dispatch(fetchStaffSuccess(response.data.data))
        })
  
        .catch(function (error) {
            dispatch(fetchStaffFailed(error))
        }); 

}

export const fetchStaffOptionListAsync = () => async(dispatch, getState) => {

    const accessToken = getState().user.accessToken
    const apiBaseName = getState().reducer.apiBaseName

    dispatch(fetchStaffStart())
    await axios({
        method: "get",
        url: `${apiBaseName}/employees/list`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(function (response) {
            dispatch(fetchStaffOptionList(response.data.data))         
        })  
        .catch(function (error) {
            dispatch(fetchStaffFailed(error))
        }); 

}