import React, { useRef } from "react";
import {
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";

import InvoiceViewSection from "./InvoiceViewSection";
import InvoiceStatusAndAction from "./InvoiceStatusAndAction";

const InvoiceDetailPage = ({ accessToken, apiBaseName, ...props }) => {
  const invoiceRef = useRef(null);

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title as="h5">Invoice Detail</Card.Title>
        </Card.Header>
      </Card>
      <Row style={{height: "80%"}}>
        <Col lg={6} style={{height: "70%"}}>
          <InvoiceViewSection innerRef={invoiceRef} {...props} />
        </Col>

        <Col lg={6} style={{height: "70%"}}>
          <InvoiceStatusAndAction invoiceRef={invoiceRef} {...props} />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.user.accessToken,
  apiBaseName: state.reducer.apiBaseName,
});

export default connect(mapStateToProps)(InvoiceDetailPage);
