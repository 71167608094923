import { Balance_Amounts_Action_Types } from "./balanceAmounts.types";

const initialState = {
  balanceAmounts: [],
  isLoading: false,
  error: null,
  pagination: {
    pageNumber: 1,
    lastPage: 1,
    perPage: 10,
    to: null,
    from: null,
    total: null,
    controlledPageCount: 1,
  },
  filter: {
    searchKey: ""
  },
  isUpdated: false,
};

const balanceAmountsReducer = (state = initialState, action = {}) => {
  const { type, payLoad } = action;

  switch (type) {
    case Balance_Amounts_Action_Types.FETCH_BALANCES_START:
      return {
        ...state,
        isLoading: true,
      };

    case Balance_Amounts_Action_Types.FETCH_BALANCES_SUCCESS:
      return {
        ...state,
        balanceAmounts: payLoad.data,
        isLoading: false,
        pagination: {
          ...state.pagination,
          pageNumber: payLoad.current_page,
          perPage: payLoad.per_page,
          lastPage: payLoad.last_page,
          to: payLoad.to,
          total: payLoad.total,
          from: payLoad.from,
          controlledPageCount: payLoad.last_page,
        },
        error: null,
      };

    case Balance_Amounts_Action_Types.FETCH_BALANCES_FAILED:
      return {
        ...state,
        error: payLoad,
      };

    case Balance_Amounts_Action_Types.SET_PAGE_SIZE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: payLoad,
        },
      };

    case Balance_Amounts_Action_Types.SET_FILTER_SEARCH_STRING:
        return {
            ...state,
            filter: {
                ...state.filter,
                searchKey: payLoad
            }
        }

    case Balance_Amounts_Action_Types.RESET_STATE:
      return {
        balanceAmounts: [],
        isLoading: false,
        error: null,
        pagination: {
          pageNumber: 1,
          lastPage: 1,
          perPage: 5,
          to: null,
          from: null,
          total: null,
          controlledPageCount: 0,
        },
        isUpdated: false,
      };

    default:
        return state
        
  }
};

export default balanceAmountsReducer
