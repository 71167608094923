
import SignIn1 from './Components/Authentication/SignIn/SignIn1';
import SignUp1 from './Components/Authentication/SignUp/SignUp1';
import InvoiceDetailPage from './Components/InvoicePage/InvoiceDetailPage/InvoiceDetailPage';

const route = [
    { path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },
    { path: '/auth/signin-1', exact: true, name: 'Signin 1', component: SignIn1 },
    // { path: '/invoiceDetail/:id', exact:true, name: 'invoiceDetail', component: InvoiceDetailPage}
];

export default route;