export const Invoices_Action_Types = {
    FETCH_INVOICES_START: "invoices/FETCH_INVOICES_START",
    FETCH_INVOICES_SUCCESS: "invoices/FETCH_INVOICES_SUCCESS",
    FETCH_INVOICES_FAILED: "invoices/FETCH_INVOICES_FAILED",
    SET_PAGE_SIZE: "invoices/SET_PAGE_SIZE",
    SET_IS_UPDATED: "invoices/SET_IS_UPDATED",
    SET_PAGE_NUMBER: "invoices/SET_PAGE_NUMBER",
    SET_FILTER_SEARCH_STRING: "invoices/SET_FILTER_SEARCH_STRING",
    SET_SEARCH_FILTER_FROM_DATE: "ivoices/SET_SEARCH_FILTER_FROM_DATE",
    SET_SEARCH_FILTER_TO_DATE: "ivoices/SET_SEARCH_FILTER_TO_DATE",
    SET_SEARCH_FILTER_PAYMENT_MODE: "ivoices/SET_SEARCH_FILTER_PAYMENT_MODE",
    FETCH_INVOICES_STATS: "invoices/FETCH_INVOICES_STATS",
    RESET_STATE: "invoices/RESET_STATE"
}