import { Clients_Action_Types } from "./clients.types";
import moment from "moment";

const initialState = {
  clients: [],
  isLoading: false,
  error: null,
  pagination: {
    pageNumber: 1,
    perPage: 10,
    lastPage: 1,
    to: null,
    total: null,
    from: null,
    controlledPageCount: 1,
  },
  filter: {
    searchString: "",
    from: "",
    to: "",
    gender: "",
  },
  isUpdated: false,
};

const clientsReducer = (state = initialState, action = {}) => {
  const { type, payLoad } = action;

  switch (type) {
    case Clients_Action_Types.FETCH_CLIENTS_START:
      return {
        ...state,
        isLoading: true,
      };

    case Clients_Action_Types.FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: payLoad.data,
        isLoading: false,
        pagination: {
          ...state.pagination,
          pageNumber: payLoad.current_page,
          perPage: payLoad.per_page,
          to: payLoad.to,
          total: payLoad.total,
          from: payLoad.from,
          controlledPageCount: payLoad.last_page,
        },
      };

    case Clients_Action_Types.FETCH_CLIENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payLoad,
      };

    case Clients_Action_Types.SET_PAGE_SIZE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: payLoad,
        },
      };

    case Clients_Action_Types.SET_IS_UPDATED:
      return {
        ...state,
        isUpdated: !state.isUpdated,
      };

    case Clients_Action_Types.SET_FILTER_SEARCH_STRING:
      return {
        ...state,
        filter: {
          ...state.filter,
          searchString: payLoad,
        },
      };

    case Clients_Action_Types.SET_FILTER_FROM_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          from: payLoad,
        },
      };

    case Clients_Action_Types.SET_FILTER_TO_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          to: payLoad,
        },
      };

    case Clients_Action_Types.SET_FILTER_GENDER:
      return {
        ...state,
        filter: {
          ...state.filter,
          gender: payLoad,
        },
      };

    case Clients_Action_Types.SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          searchString: payLoad?.searchString,
          from: payLoad?.fromDate
            ? new moment(payLoad.fromDate).format("YYYY-MM-DD")
            : null,
          to: payLoad?.toDate
            ? new moment(payLoad.toDate).format("YYYY-MM-DD")
            : null,
          gender: payLoad?.gender,
        },
      };

    case Clients_Action_Types.RESET_STATE:
      return {
        clients: [],
        isLoading: false,
        error: null,
        pagination: {
          pageNumber: 1,
          perPage: 10,
          lastPage: 1,
          to: null,
          total: null,
          from: null,
          controlledPageCount: 1,
        },
        filter: {
          searchString: "",
          from: "",
          to: "",
          gender: "",
        },
        isUpdated: false,
      };

    default:
      return state;
  }
};

export default clientsReducer;
