export default {
    items: [
        {
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard',
                    icon: 'feather icon-home',
                    breadcrumbs: false,
                },
                {
                    id: 'clients-page',
                    title: 'Clients',
                    type: 'item',
                    url: '/clients',
                    icon: 'feather icon-users'
                },
                {
                    id: 'invoice-page',
                    title: 'Invoice',
                    type: 'item',
                    url: '/invoice',
                    icon: 'feather icon-clipboard',
                    breadcrumbs: false
                },
                {
                    id: 'staff-page',
                    title: 'Staff',
                    type: 'item',
                    url: '/staff',
                    icon: 'feather icon-user',
                    breadcrumbs: false
                },
                {
                    id: 'inventory-page',
                    title: 'Inventory',
                    type: 'item',
                    url: '/inventory',
                    icon: 'feather icon-book',
                    breadcrumbs: false
                },
                {
                    id: 'reports-page',
                    title: 'Reports',
                    type: 'item',
                    url: '/reports',
                    icon: 'feather icon-book',
                    breadcrumbs: true
                }
            ]
        }, 
        {
            id: 'settings-navigation',
            title: 'settings',
            type: 'group',
            icon: ' icon-settings',
            children: [
                {
                    id: 'settings',
                    title: 'Settings',
                    type: 'collapse',
                    icon: 'feather icon-settings',
                    children: [
                        {
                            id: 'manage-feedback',
                            title: 'Manage Feedback',
                            type: 'item',
                            url: '/settings/manage-feedback',
                        },
                        {
                            id: 'sms-setting',
                            title: 'SMS Setting',
                            type: 'item',
                            url: '/settings/sms-setting',
                        },
                        {
                            id: 'other-settings',
                            title: 'Other Settings',
                            type: 'item',
                            url: '/settings/other-setting',
                        },
                        {
                            id: 'data-import',
                            title: 'Data Import',
                            type: 'item',
                            url: '/settings/data-import',
                        },
                        {
                            id: 'invoice-setting',
                            title: 'Invoice Setting',
                            type: 'item',
                            url: '/settings/invoice-setting'
                        },
                        {
                            id: 'manage-expenses',
                            title: 'Manage Expenses',
                            type: 'item',
                            url: '/settings/manage-expenses'
                        },    
                        {
                            id:'manage-taxes',
                            title: 'Manage Taxes',
                            type: 'item',
                            url: '/settings/manage-taxes'
                        },
                        {
                            id: 'vouchers-prepaid-and-giftcards',
                            title: 'Vouchers Prepaids and Giftcards',
                            type: 'item',
                            url: '/settings/vouchers-prepaid-and-giftcards'
                        },
                        {
                            id: 'packages',
                            title: 'Packages',
                            type: 'item',
                            url: '/settings/packages'
                        },
                        {
                            id: 'memberships',
                            title: 'Memberships',
                            type: 'item',
                            url: '/settings/memberships'
                        }
                    ]
                },
                
            ]
        },
        
    ]
}