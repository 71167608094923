
import { Products_Action_Types } from "./products.types";

const initialState = {
  products: [],
  productsLoading: false,
  error: null,
  pagination: {
    pageNumber: 1,
    perPage: 10,
    to: null,
    total: null,
    from: null,
    controlledPageCount: 1,
  },
  filter: {
    searchString: ""
  },
  productsUpdated: false,
  productsOptionList: [],
};

const productReducer = (state = initialState, action = {}) => {
  const { type, payLoad } = action;

  switch (type) {
    case Products_Action_Types.FETCH_PRODUCTS_START:
      return { ...state, productsLoading: true };
    case Products_Action_Types.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsLoading: false,
        products: payLoad.data,
        pagination: {
          ...state.pagination,
          pageNumber: payLoad.current_page,
          perPage: payLoad.per_page,
          to: payLoad.to,
          total: payLoad.total,
          from: payLoad.from,
          controlledPageCount: payLoad.last_page,
        },
      };

    case Products_Action_Types.FETCH_PRODUCTS_OPTION_LIST:
      return {
        ...state,
        productsOptionList: payLoad.data.map((product) => ({
          ...product,
          value: product.id,
          label: product.product_name,
        })),
        productsLoading: false,
      };

    case Products_Action_Types.FETCH_PRODUCTS_FAILURE:
      return{
        ...state,
        error: payLoad,
        productsLoading: false
      }

    case Products_Action_Types.SET_PAGE_SIZE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: payLoad,
        },
      };
    case Products_Action_Types.SET_IS_UPDATED:
      return {
        ...state,
        productsUpdated: !state.productsUpdated,
      };

    case Products_Action_Types.SET_FILTER_SEARCH_STRING:
      return{
        ...state,
        filter: {
            searchString: payLoad
        }
      }

    default:
      return state;
  }
};

export default productReducer;
