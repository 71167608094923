import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";

const IndividualServiceAmount = ({
  service,
  
  staffList,
  staffLoading,
  setservices,
  services,
}) => {
  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
      borderRadius: "0.375rem",
    }),
  };

  const handleAdd = () => {
    if (service.child.length < 3) {
      let serviceSplit = service.child.map((splitService) => {
        return {
          ...splitService,
          amount: service.product_s_price / (service.child.length + 1),
        };
      });

      const newServiceSplit = {
        ...service.child[0],
        amount: service.product_s_price / (service.child.length + 1),
        stylist: null,
      };
      serviceSplit.push(newServiceSplit);

      const updatedServices = services.map((parentService) => {
        if (parentService.id == service.id) {
          parentService.child = serviceSplit;
        }
        return parentService;
      });
      setservices(updatedServices);
    }
  };

  const handleMinus = () => {
    if (service.child.length > 1) {
      let serviceSplit = service.child.map((splitService) => {
        if (service.child.indexOf(splitService) < service.child.length - 1) {
          return {
            ...splitService,
            amount: service.product_s_price / (service.child.length - 1),
          };
        } else {
          return;
        }
      });

      serviceSplit.pop();

      const updatedServices = services.map((parentService) => {
        if (parentService.id == service.id) {
          parentService.child = serviceSplit;
        }
        return parentService;
      });
      setservices(updatedServices);
    }
  };

  const handleStylistChange = (stylist, id) => {
    let subService = service.child.find((subService) => {
      return subService === service.child[id];
    });
    const updatedSubService = {
      ...subService,
      stylist: stylist,
    };

    const updatedServices = services.map((parentService) => {
      if(parentService === service){
        const updatedService = parentService.child.map(childService => {
          if(childService === subService){
            return updatedSubService
          } 
          return childService
        })
        return {...parentService, child: updatedService}
      }
      else return parentService
    })
    setservices(updatedServices);
  };

  const handleInputChange = (id, e) => {
    const value = e.target.value;

    let subService = service.child.find((subService) => {
      return subService === service.child[id];
    });

    const updatedSubService = {
      ...subService,
      amount: value,
    };
    console.log(updatedSubService)

    const updatedServices = services.map((parentService) => {
      if(parentService === service){
        const updatedService = parentService.child.map(childService => {
          if(childService === subService){
            return updatedSubService
          } 
          return childService
        })
        return {...parentService, child: updatedService}
      }
      else return parentService
    })
    setservices(updatedServices);
  };

  return (
    <div className="p-3 border-bottom">
      <div className="mb-3">
        <span className="f-w-600 f-14">{service.product_name}</span>-
        <span className="f-w-600 f-14">{service.product_s_price}</span>
      </div>
      {service.child.map((cservice, id) => {
        return (
          <Row className="mb-2">
            <Col lg={5}>
              <Select
                value={cservice.stylist}
                isLoading={staffLoading}
                options={staffList}
                styles={customSelectStyles}
                onChange={(stylist) => handleStylistChange(stylist, id)}
                isDisabled={id === 0}
              />
            </Col>
            <Col lg={5}>
              <Form.Control
                value={cservice.amount}
                type="number"
                onChange={(e) => handleInputChange(id, e)}
              />
            </Col>
            <Col lg={2}>
              {id >= 1 && (
                <span
                  className="feather icon-x text-c-red f-20"
                  onClick={() => handleMinus()}
                  style={{ cursor: "pointer" }}
                ></span>
              )}
            </Col>
          </Row>
        );
      })}

      {service.child.length < 3 && (
        <span
          className="feather icon-plus text-c-green f-20"
          style={{ cursor: "pointer" }}
          onClick={() => handleAdd()}
        ></span>
      )}
    </div>
  );
};

export default IndividualServiceAmount;
