import React, {useEffect, useState} from "react";
import {
  Modal,
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import axios from "axios"
import {connect} from "react-redux"


const PaymentDueModal = ({setdueModalOpen, balanceDetail, accessToken, apiBaseName, orderId}) => {
  const [balanceDetailLoading, setbalanceDetailLoading] = useState(false)
  const [balanceInfo, setbalanceInfo] = useState(null)

  const abortController = new AbortController()

  useEffect(() => {
    if(!balanceDetail){
      setbalanceDetailLoading(true)
      axios({
        method: "post",
        url: `${apiBaseName}/balance-amounts/order/${orderId}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        signal: abortController.signal,
      })
        .then(function (response) {
          setbalanceInfo(response.data.data)
          setbalanceDetailLoading(false)
        })
        .catch(function (error) {
          setbalanceDetailLoading(false)
          console.log(error);
        });
    }
  
    return () => {
      abortController.abort()
    }
  }, [])

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
      borderRadius: "0.375rem",
    }),
  };

  const PaymentMethods = [
    { value: "Cash", label: "Cash" },
    { value: "Cheque", label: "Cheque" },
    { value: "Credit Card", label: "Credit Card" },
    { value: "Debit Card", label: "Debit Card" },
    { value: "NEFT", label: "NEFT" },
    { value: "UPI", label: "UPI" },
    { value: "Online", label: "Online" },
    { value: "GPay", label: "GPay" },
    { value: "PhonePe", label: "PhonePe" },
    { value: "Paytm", label: "Paytm" },
    { value: "BharatPay", label: "BharatPay" },
    { value: "Amazon Pay", label: "Amazon Pay" },
    { value: "WhatsApp Pay", label: "WhatsApp Pay" },
    { value: "Other", label: "Other" },
  ];

  const initialValues = {
    paymentMode: { value: "Cash", label: "Cash" },
    amount: balanceDetail ? balanceDetail.balance_amount : balanceInfo ? balanceInfo.balance_amount : 0,
    date: new moment().toDate(),
    note: "",
  };

  const validationSchema = Yup.object().shape({
    paymentMode: Yup.object().required("Field required").nullable(),
    amount: Yup.number().positive().required("Field required"),
  });

  const handleSubmit = (values) => {
    console.log(values)
    setdueModalOpen(false)
  }

  return (
    <>
      <Modal.Header closeButton closeLabel="Close">
        <Modal.Title as="h5">Payment Due</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {(props) => {
          const {
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
            isSubmitting,
          } = props;

          return (
            <Form noValidate>
              <Modal.Body>
                {
                  balanceDetailLoading ? <div className="text-center"><Spinner animation="border"/></div> : balanceInfo ? <>
                    <div className="d-flex justify-content-between f-12 f-w-600">
                  <span>Invoice Date: {new moment(balanceInfo.created_at).format("DD-MMM-YYYY")}</span>
                  <span >Amount Due: <span className="text-c-red">{balanceInfo.balance_amount}</span></span>
                </div>
                <div className="f-12 f-w-600">
                  <span>Total Amount: {balanceInfo.total_amount}</span>
                </div>
                <Row className="mb-3 mt-3">
                  <Col>
                    <FormGroup>
                      <FormLabel>Payment Mode</FormLabel>
                      <Select
                        styles={customSelectStyles}
                        options={PaymentMethods}
                        value={values.paymentMode}
                        onChange={(value) =>
                          setFieldValue("paymentMode", value)
                        }
                      />
                      <div className="error">{errors.paymentMode}</div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <FormLabel>Amount</FormLabel>
                      <FormControl
                        type="number"
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.amount && !!errors.amount}
                      />
                      <FormControl.Feedback type="invalid">
                        {errors.amount}
                      </FormControl.Feedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <FormLabel>Date</FormLabel>
                      <DatePicker
                        className="form-control"
                        selected={values.date}
                        dateFormat={"dd-MMM-yyyy"}
                        onChange={val => setFieldValue("date", val)}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <FormLabel>Note</FormLabel>
                      <FormControl
                        type="text"
                        name="note"
                        placeholder="note"
                        value={values.note}
                        onChange={handleChange}
                        isInvalid={touched.note && !!errors.note}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                  </> : balanceDetail ? <>
                    <div className="d-flex justify-content-between f-12 f-w-600">
                  <span>Invoice Date: {new moment(balanceDetail.created_at).format("DD-MMM-YYYY")}</span>
                  <span >Amount Due: <span className="text-c-red">{balanceDetail.balance_amount}</span></span>
                </div>
                <div className="f-12 f-w-600">
                  <span>Total Amount: {balanceDetail.total_amount}</span>
                </div>
                <Row className="mb-3 mt-3">
                  <Col>
                    <FormGroup>
                      <FormLabel>Payment Mode</FormLabel>
                      <Select
                        styles={customSelectStyles}
                        options={PaymentMethods}
                        value={values.paymentMode}
                        onChange={(value) =>
                          setFieldValue("paymentMode", value)
                        }
                      />
                      <div className="error">{errors.paymentMode}</div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <FormLabel>Amount</FormLabel>
                      <FormControl
                        type="number"
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.amount && !!errors.amount}
                      />
                      <FormControl.Feedback type="invalid">
                        {errors.amount}
                      </FormControl.Feedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <FormLabel>Date</FormLabel>
                      <DatePicker
                        className="form-control"
                        selected={values.date}
                        dateFormat={"dd-MMM-yyyy"}
                        onChange={val => setFieldValue("date", val)}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <FormLabel>Note</FormLabel>
                      <FormControl
                        type="text"
                        name="note"
                        placeholder="note"
                        value={values.note}
                        onChange={handleChange}
                        isInvalid={touched.note && !!errors.note}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                  </> : null
                }
                
              </Modal.Body>
              <Modal.Footer>
                <button className="btn-theme2" type="button" onClick={() => setdueModalOpen(false)}>Cancel</button>
                <button className="btn-theme" type="submit">Pay</button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.user.accessToken,
  apiBaseName: state.reducer.apiBaseName
})

export default connect(mapStateToProps)(PaymentDueModal);
