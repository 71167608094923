import React, { useState, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";

const InvoiceViewSection = ({ apiBaseName, accessToken, match, innerRef }) => {
    const [invoiceLoading, setinvoiceLoading] = useState(false);
    const [invoice, setinvoice] = useState({ __html: "" });

    const abortController = new AbortController();
    useEffect(() => {
      setinvoiceLoading(true);
      axios({
        method: "get",
        url: `${apiBaseName}/orders-main/invoiceBilling/${match.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        signal: abortController.signal,
      })
        .then(function (response) {
          setinvoice({ __html: response.data });
          setinvoiceLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setinvoiceLoading(false);
        });

      return () => {
        abortController.abort();
      };
    }, []);

    return (
      <Card style={{ maxHeight: "800px", overflow: "auto" }}>
        {invoiceLoading ? (
          <div
            className="d-flex flex-col justify-content-center align-items-center"
            style={{ height: "800px" }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <div dangerouslySetInnerHTML={invoice} ref={innerRef}></div>
        )}
      </Card>
    );
  }

const mapStateToProps = (state) => ({
  accessToken: state.user.accessToken,
  apiBaseName: state.reducer.apiBaseName,
});

export default connect(mapStateToProps)(InvoiceViewSection);