import { USER_ACTION_TYPES } from "./user.types"

export const setAccessToken = (accessToken) => ({
    type: USER_ACTION_TYPES.SET_ACCESS_TOKEN,
    payLoad: accessToken
})

export const deleteAccessToken = () => ({
    type: USER_ACTION_TYPES.DELETE_ACCESS_TOKEN
})

export const changeIsLoggedIn = () => ({
    type: USER_ACTION_TYPES.SET_IS_LOGGED_IN
})

export const setDay = () => ({
    type: USER_ACTION_TYPES.SET_DAY
})