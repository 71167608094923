import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import IndividualServiceAmount from "./IndividualServiceAmount";
import { connect } from "react-redux";
import { fetchStaffOptionListAsync } from "../../../store/staff/staff.actions";
import axios from "axios";
import {toast} from "react-toastify"

const SplitServiceAmountModal = ({
  serviceArray,
  staff,
  getStaff,
  setModalOpen,
  accessToken,
  apiBaseName,
  setisUpdated,
  isUpdated
}) => {
  const [services, setservices] = useState([]);
  const [isSubmitting, setisSubmitting] = useState(false)

  const abortController = new AbortController();

  useEffect(() => {
    if (staff.staffOptionList.length > 0) {
      const newArray = serviceArray.map((service) => {
        if (service.child.length == 0) {
          const newChild = [
            {
              ...service,
              stylist: staff.staffOptionList.find(
                (staff) => staff.value === service.employee_id
              ),
              amount: service.product_s_price,
            },
          ];
          return {...service, child: newChild};
        } else {
          const alteredChildsList = service.child.map((childService) => ({
            ...childService,
            stylist: staff.staffOptionList.find(
              (staff) => staff.value === childService.employee_id
            ),
            amount: childService.product_s_price,
          }));
          return {...service, child: alteredChildsList};
        }
      });
      setservices(newArray);
    }
  }, [staff.staffOptionList]);
  console.log(services);

  useEffect(() => {
    getStaff();
  }, []);

  const handleSubmit = () => {
    const servicesData = services.map((service) => {
      const updatedService = service.child.map((splitService) => {
        return {
          ...splitService,
          employee_id: splitService.stylist.value,
          product_s_price: splitService.amount,
        };
      });
      return {...service, child:updatedService};
    });
    const data = JSON.stringify({ services: servicesData }, null, 10);
    setisSubmitting(true)
      axios({
        method: "post",
        url: `${apiBaseName}/orders/split-service`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        signal: abortController.signal,
        data: data,
      })
        .then(function (response) {
          console.log(response);
          setisSubmitting(false);
          setisUpdated(!isUpdated);
          toast.success("Service split successfully");
          setModalOpen(false);
        })
        .catch(
          function (error) {
            console.log(error)
            setisSubmitting(false)
            toast.error("Cannot split service")
          });

    return () => {
      abortController.abort();
    };
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Split Service Amount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {staff.isLoading ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          services.map((service, index) => (
            <IndividualServiceAmount
              service={service}
              key={index}
              staffList={staff.staffOptionList}
              staffLoading={staff.isLoading}
              services={services}
              setservices={setservices}
            />
          ))
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-theme2"
          type="button"
          onClick={() => setModalOpen(false)}
        >
          Cancel
        </button>
        <button className="btn-theme" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? <Spinner animation="border" as="span" size="sm"/> : "Submit"}
        </button>
      </Modal.Footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  staff: state.staff,
  accessToken: state.user.accessToken,
  apiBaseName: state.reducer.apiBaseName,
});

const mapDispatchToProps = (dispatch) => ({
  getStaff: () => dispatch(fetchStaffOptionListAsync()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitServiceAmountModal);
