import { Service_Action_Types } from "./services.types";

const initialState = {
  services: [],
  servicesLoading: false,
  servicesError: null,
  pagination: {
    pageNumber: 1,
    perPage: 10,
    to: null,
    total: null,
    from: null,
    controlledPageCount: 1,
  },
  filter: {
    searchString: ""
  },
  isUpdated: false,
  servicesOptionList: [],
};

const serviceReducer = (state = initialState, action = {}) => {
  const { type, payLoad } = action;

  switch (type) {
    case Service_Action_Types.FETCH_SERVICES_START:
      return { 
        ...state, 
        servicesLoading: true 
      };

    case Service_Action_Types.FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        services: payLoad.data,
        servicesLoading: false,
        pagination: {
          ...state.pagination,
          pageNumber: payLoad.current_page,
          perPage: payLoad.per_page,
          to: payLoad.to,
          total: payLoad.total,
          from: payLoad.from,
          controlledPageCount: payLoad.last_page,
        },
      };

    case Service_Action_Types.FETCH_SERVICES_OPTION_LIST:
      return {
        ...state,
        servicesOptionList: payLoad.data.map((service) => ({
          ...service,
          value: service.id,
          label: service.product_name,
        })),
        servicesLoading: false,
      };

    case Service_Action_Types.FETCH_SERVICES_FAILED:
      return { 
        ...state, 
        servicesLoading: false, 
        servicesError: payLoad 
      };

    case Service_Action_Types.SET_IS_UPDATED:
      return { 
        ...state, 
        isUpdated: !state.isUpdated 
      };

    case Service_Action_Types.SET_PAGE_SIZE:
      return {
        ...state,
        pagination: { 
          ...state.pagination, 
          perPage: payLoad 
        },
      };

    case Service_Action_Types.SET_FILTER_SEARCH_STRING:
      return {
        ...state,
        filter: {
          searchString: payLoad
        }
      }

    default:
      return state;
  }
};

export default serviceReducer;
