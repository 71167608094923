import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import axios from 'axios'
import * as Yup from 'yup'
import Aux from "../../../../hoc/_Aux";
import Spinner from 'react-bootstrap/Spinner'
import { setDay } from '../../../../store/user/user.actions'
import { Redirect} from 'react-router-dom'
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export const DrawerCash = ({ accessToken, defaultPath, setDay, apiBaseName}) => {
    const [loading, setloading] = useState(false)
    useEffect(() => {
        const controller = new AbortController()
        return () => {
            controller.abort()
        }
    }, [])
    const initialValues = {
        drawerCash: ""
    }

    const validationSchema = Yup.object().shape({
        drawerCash: Yup.number("only numbers accepted").required("Drawer cash is required")
    })

    const handleSubmit = async (values) => {
        const data = JSON.stringify({
            "drawer_cash": values.drawerCash
        })
        setloading(true)
        if (accessToken !== "") {
            await axios({
                method: 'post',
                url: `${apiBaseName}/users/drawercash`,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            }).then(function (response) {
                setloading(false)
                setDay()
                toast.success(`Drawer cash of Rs. ${values.drawerCash}  added successfully`)
                return <Redirect push to={defaultPath}/> 
            }).catch(function (error) {
                console.log(error.response)
                if(error.response){
                    toast.error(error.response.data.message)
                }
                else{
                    toast.error("Oops. Something went wrong.")
                }
                setloading(false)
            })

        }
        else{
            setloading(false)
        }
        
    }
    return (
        <Aux>
            <div className="card">
                <div className="card-body text-center">
                    <div className="mb-4">
                        <i className="feather icon-briefcase auth-icon" />
                    </div>
                    <h3 className="mb-4">Drawer Cash</h3>
                    <Formik initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}>
                        <Form>
                            <div className="input-group mb-3">
                                <Field
                                    type="number"
                                    id="number"
                                    name="drawerCash"
                                    className="form-control"
                                    placeholder="Drawer cash"
                                    style={{ width: '100%' }}
                                />
                                <ErrorMessage name='drawerCash'>{msg => <div className='error'>{msg}</div>}</ErrorMessage>
                            </div>


                            <button className="btn-theme f-14" type="submit" style={{minWidth: '55px'}} disabled={loading}>
                                {loading && <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                />
                                }
                                {!loading && <span>Continue</span>}
                            </button>
                        </Form>
                    </Formik>
                </div>
            </div>
        </Aux>
    )
}

const mapStateToProps = (state) => ({
    accessToken: state.user.accessToken,
    defaultPath: state.reducer.defaultPath,
    day: state.user.day,
    apiBaseName: state.reducer.apiBaseName
})

const mapDispatchToProps = (dispatch) => ({
    setDay: () => dispatch(setDay())
})
export default connect(mapStateToProps, mapDispatchToProps)(DrawerCash)
