
import './user.actions'
import { USER_ACTION_TYPES } from './user.types'

const initialState = {
    isLoggedIn: false,
    accessToken: "",
    day: "4"
}

const userReducer = (state=initialState, action) => {
    switch(action.type){

        case USER_ACTION_TYPES.SET_IS_LOGGED_IN:
            return {
                ...state, isLoggedIn: !state.isLoggedIn
            }
        case USER_ACTION_TYPES.SET_ACCESS_TOKEN :
            return {
                ...state, accessToken: action.payLoad 
            }
        case USER_ACTION_TYPES.SET_DAY:{
            return{
                ...state, day: new Date().getDate().toString()
            }
        }
        case USER_ACTION_TYPES.DELETE_ACCESS_TOKEN:{
            return{
                ...state, accessToken: ""
            }
        }
        default:{
            return state
        }
    }
}

export default userReducer

