import { Vouchers_Action_Types } from "./vouchers.types"

const initialState = {
    vouchers: [],
    vouchersListByType: [],
    isLoading: false,
    error: null,
    pagination: {
        pageNumber: 1,
        perPage: 10,
        lastPage: 1,
        to: null,
        from: null,
        total: null,
        controlledPageCount: 1,
    },
    filter: {
        searchString: ""
    },
    isUpdated: false
}

const vouchersReducer = (state=initialState, action={}) => {
    const {type, payLoad} = action

    switch(type){
        case Vouchers_Action_Types.FETCH_VOUCHERS_START:
            return {
                ...state, 
                isLoading: true
            }

        case Vouchers_Action_Types.FETCH_VOUCHERS_SUCCESS:
            return {
                ...state,
                vouchers: payLoad.data,
                isLoading: false,
                pagination: {
                    ...state.pagination,
                    pageNumber: payLoad.current_page,
                    perPage: payLoad.per_page,
                    lastPage: payLoad.last_page,
                    total: payLoad.total,
                    to: payLoad.to,
                    from: payLoad.from,
                    controlledPageCount: payLoad.last_page,
                },
                error: null,
            };
        
        case Vouchers_Action_Types.FETCH_VOUCHERS_LIST_BY_TYPE:
            return {
                ...state,
                vouchersListByType: payLoad.data.map((item) => ({
                    ...item,
                    value: item.id,
                    label: item.package_name,
                  })),
            }

        case Vouchers_Action_Types.FETCH_VOUCHERS_FAILED:
            return {
                ...state,
                isLoading: false, 
                error: payLoad
            }

        case Vouchers_Action_Types.SET_FILTER_SEARCH_STRING:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    searchString: payLoad
                }
            }

        case Vouchers_Action_Types.SET_PAGE_SIZE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: payLoad
                }
            }
        
        case Vouchers_Action_Types.SET_IS_UPDATED:
            return {
                ...state,
                isUpdated: !state.isUpdated
            }

        default:
            return state
        }

    }

export default vouchersReducer
